<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 固定头部页 -->
    <el-container class="page">
        <el-header style="height: 72px">
            <!-- 头部layoout -->
            <div class="head">
                <!-- 左边logo 文字 -->
                <router-link to="/"><img :src="logoIcon" alt="logo" /></router-link>
                <!-- 右边登录注册 -->
                <ul class="head_reight">
                    <li>
                        <p class="user_login">
                            <el-avatar :src="userData.face_url" :size="20"></el-avatar>
                            <el-dropdown>
                                <el-button type="text">
                                    {{ userData.display_name }}
                                </el-button>
                                <el-dropdown-menu slot="dropdown" placement="bottom">
                                    <el-dropdown-item>
                                        <el-button type="text" @click="account"> 我的反馈 </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-button type="text" @click="exit"> 退出 </el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </p>
                    </li>
                </ul>
            </div>
        </el-header>
        <!-- 主要内容区域 -->
        <el-main>
            <!-- 内容title -->
            <div class="el-main_nav">
                <p class="el-main_nav_title">发起版权投诉</p>
            </div>
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'index',
    components: {},
    data() {
        return {
            logoIcon: require('@/assets/img/logo.svg'),
            userData: {},
        };
    },
    mounted() {
        const userInfo = sessionStorage.getItem('user');
        if (userInfo) {
            const { face_url, display_name } = JSON.parse(userInfo);
            this.userData = {
                face_url,
                display_name,
            };
        }
    },
    methods: {
        account() {
            this.$router.push({
                path: '/record',
            });
        },
        exit() {
            let port = location.port;
            if (port) {
                window.location.href = 'https://passport.qatest.baidu.com/?logout&u=' + this.baseUrl;
            } else {
                window.location.href = 'https://passport.baidu.com/?logout&u=' + this.baseUrl;
            }
        },
    },
};
</script>
<style scoped>
.page {
    width: 100vw;
    height: 100vh;
}
/* 头部样式 */
.el-header {
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(29, 51, 92, 0.02), inset 0 -1px 0 0 rgba(215, 215, 215, 0.5);
    color: #333;
    text-align: center;
    line-height: 72px;
    width: 1440px;
}
/* 头部layout */
.head {
    /* background-color: red; */
    width: 1200px;
    height: 72px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
}
.head img {
    width: 220px;
}
.head_reight {
    /* background-color: pink; */
    display: flex;
    justify-content: space-between;
}
.head_reight a {
    font-size: 14px;
    color: #1f1f1f;
    letter-spacing: 0;
    text-align: center;
}
.user_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.el-dropdown {
    margin-left: 10px;
}
/* 主要内容 */
.el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /* line-height: 160px; */
    height: calc(100% - 150px);
    width: 1440px;
}
.el-main_nav {
    width: 1200px;
    height: 76px;
    margin: auto;
    /* background-color: pink; */
    margin-top: -20px;
    overflow: hidden;
}
.el-main_nav_title {
    font-size: 24px;
    color: #1e2024;
    line-height: 24px;
    /* background-color: red; */
    width: 144px;
    height: 24px;
    margin-top: 30px;
}

/* 头部中线 */
.line {
    background: #d8d8d8;
    width: 1px;
    height: 16px;
    margin-top: 27px;
}
.el-dropdown-menu__item {
    width: 60px;
}
</style>
